/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d81406ac-de12-40ea-998b-7a417de8a94b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ppkCpXhYW",
    "aws_user_pools_web_client_id": "3uhq63vcj1menb6nd7da5bu7r1",
    "oauth": {},
    "aws_user_files_s3_bucket": "nixboxbloguploadsprod-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
